import './App.css';
import PageHandler from './components/PageHandler'

function App() {
  return (
    <div style={{margin: '10vmin 14vmin', backgroundColor: 'white'}}>
      <PageHandler />
    </div>
  );
}

export default App;
